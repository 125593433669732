<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content searchWrapperContent">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId" @change="areaChange" filterable size="15" placeholder="请选择">
              <el-option label="全部" :value="allAreaList"></el-option>
              <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.streetId"
              filterable
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.park_name')">
            <my-component
              ref="parkInput"
              :areaIds="areaIds"
              :operationId="formInline.operationId"
              @valueChange="completeValue"
              slaveRelations="0,1"
            >
            </my-component>
          </el-form-item>
          <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
            <el-autocomplete
              ref="plateNumber"
              size="11"
              valueKey="plateNumber"
              class="inline-input"
              v-model="formInline.plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="车牌号"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
            <el-select v-model.trim="formInline.entryOrExit" filterable size="8">
              <el-option
                :label="item.desc"
                :value="item.code"
                :key="item.code"
                v-for="item in ExitTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.time_slot')">
            <!-- <el-date-picker v-model="date1" type="datetime" placeholder="选择日期" @change="showLog"></el-date-picker>
            至
            <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="showLog"></el-date-picker> -->
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defalutDate="defalutDate"
            />
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searParkRecordList();
            "
            :loading="loading"
            >查询
          </el-button>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="fn(scope)"
                v-if="scope.row.actualPay == '0' && $route.meta.authority.button.applyFor"
                disabled
                >退款申请
              </el-button>
              <el-button
                type="text"
                @click="fn(scope)"
                v-if="scope.row.actualPay != '0' && $route.meta.authority.button.applyFor"
                >退款申请
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
      <el-dialog title="退款申请" :visible.sync="dialogVisible" width="60%">
        <el-steps
          :space="200"
          :active="0"
          finish-status="success"
          style="margin-left: 18%"
          align-center
        >
          <el-step title="申请退款"></el-step>
          <el-step title="审批退款"></el-step>
          <el-step title="确认退款"></el-step>
        </el-steps>

        <div>
          <h1 class="titleh1">停车记录</h1>
          <el-table :data="tableData2" style="width: 100%">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
          <h1 class="titleh1">支付记录</h1>
          <el-table v-loading="loading" :data="refundrRecord" style="width: 100%">
            <el-table-column type="index" label="选择订单" width="100" align="center">
              <template slot-scope="scope">
                <el-radio
                  class="radio"
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row, scope.$index)"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableOrder"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
        </div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="退款原因:">
            <el-select
              v-model.trim="form.region"
              filterable
              size="15"
              placeholder="出场延迟"
              @change="changeReason()"
            >
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in causeDownData"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="退款方式:">
            <!--<el-input v-model="form.date1" style="width: 130px;" placeholder="请输入内容"></el-input>-->
            <span>原路退回</span>
          </el-form-item>
          <!--实际出场时间-->
          <el-form-item label="实际出场时间:" v-show="form.region === 0">
            <el-date-picker
              popper-class="apply_for_date_picker"
              v-model="form.value1"
              type="datetime"
              :picker-options="endPickerOptions"
              placeholder="选择日期时间"
              @change="calculate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="退款计算:">
            <el-table style="width: 90%" :data="refundTableData">
              <el-table-column align="center" label="">
                <template slot-scope="scope">
                  <p v-show="scope.$index == 0">原订单</p>
                  <p v-show="scope.$index == 1">修改后</p>
                  <p v-show="scope.$index == 2">退款</p>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="date" label="应付金额" width="100">
                <template slot-scope="scope">
                  <p v-if="form.region == 0 && scope.$index == 0 && refundrRecordLength < 2">
                    {{
                      scope.row.shouldPayMoney
                        ? (scope.row.shouldPayMoney / 100).toFixed(2)
                        : "0.00"
                    }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 0 && refundrRecordLength >= 2">
                    {{ scope.row.shouldPayMoney ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 1">
                    {{
                      scope.row.shouldPayMoney != undefined
                        ? (scope.row.shouldPayMoney / 100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <!--<p v-if="form.region == 0 && scope.$index == 2">{{currentChargeVO.shouldPayMoney ? (originalChargeVO.shouldPayMoney - currentChargeVO.shouldPayMoney) / 100 .toFixed(2) : ''}}</p>-->
                  <p v-if="form.region == 1 && scope.$index == 1">0.00</p>
                  <!--<p v-if="form.region == 1 && scope.$index == 2">{{scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : ''}}</p>-->
                  <p v-if="form.region == 1 && scope.$index == 0">
                    {{
                      scope.row.shouldPayMoney
                        ? (scope.row.shouldPayMoney / 100).toFixed(2)
                        : "0.00"
                    }}
                  </p>
                  <p v-if="form.region == 2 && scope.$index == 0">
                    {{
                      scope.row.shouldPayMoney
                        ? (scope.row.shouldPayMoney / 100).toFixed(2)
                        : "0.00"
                    }}
                  </p>
                  <!--<input style="width: 80%" type="text" v-model="scope.row.shouldPayMoney" v-if="form.region === 2 && scope.$index === 2">-->
                </template>
              </el-table-column>
              <el-table-column align="center" prop="name" label="停车卡抵扣" width="100">
                <template slot-scope="scope">
                  <p v-if="form.region == 0 && scope.$index == 0">
                    {{ (scope.row.parkCardMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 1">
                    {{
                      scope.row.parkCardMoney != undefined
                        ? (scope.row.parkCardMoney / 100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 2">
                    {{
                      currentChargeVO.parkCardMoney != undefined
                        ? (originalChargeVO.parkCardMoney - currentChargeVO.parkCardMoney) /
                          (100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 1">0.00</p>
                  <p v-if="form.region == 1 && scope.$index == 2">
                    {{ (scope.row.parkCardMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 0">
                    {{ (scope.row.parkCardMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 2 && scope.$index == 0">
                    {{ (scope.row.parkCardMoney / 100).toFixed(2) }}
                  </p>
                  <input
                    style="width: 80%"
                    type="text"
                    v-model="actualparkCardMoney"
                    v-if="form.region === 2 && scope.$index === 2"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" prop="address" width="100" label="优惠券金额">
                <template slot-scope="scope">
                  <!--<p v-if="form.region !== 2|| scope.$index !== 2">{{ scope.row.couponMoney }}</p>-->
                  <p v-if="form.region == 0 && scope.$index == 0">
                    {{ (scope.row.couponMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 1">
                    {{
                      scope.row.couponMoney != undefined
                        ? (scope.row.couponMoney / 100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 2">
                    {{
                      currentChargeVO.couponMoney != undefined
                        ? (originalChargeVO.couponMoney - currentChargeVO.couponMoney) /
                          (100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 1">0.00</p>
                  <p v-if="form.region == 1 && scope.$index == 2">
                    {{ (scope.row.couponMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 0">
                    {{ (scope.row.couponMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 2 && scope.$index == 0">
                    {{ (scope.row.couponMoney / 100).toFixed(2) }}
                  </p>
                  <input
                    style="width: 80%"
                    type="text"
                    v-model="actualcouponMoney"
                    v-if="form.region === 2 && scope.$index === 2"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" prop="address" width="110" label="车场折扣金额">
                <template slot-scope="scope">
                  <p v-if="form.region == 0 && scope.$index == 0">
                    {{ (scope.row.discountMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 1">
                    {{
                      scope.row.discountMoney != undefined
                        ? (scope.row.discountMoney / 100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 2">
                    {{
                      currentChargeVO.discountMoney != undefined
                        ? (originalChargeVO.discountMoney - currentChargeVO.discountMoney) /
                          (100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 0">
                    {{ (scope.row.discountMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 1">0.00</p>
                  <p v-if="form.region == 1 && scope.$index == 2">
                    {{ (scope.row.discountMoney / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 2 && scope.$index == 0">
                    {{ (scope.row.discountMoney / 100).toFixed(2) }}
                  </p>
                  <input
                    style="width: 80%"
                    type="text"
                    v-model="actualdiscountMoney"
                    v-if="form.region === 2 && scope.$index === 2"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" prop="address" width="90" label="实付金额">
                <template slot-scope="scope">
                  <p v-if="form.region == 0 && scope.$index == 0 && refundrRecordLength < 2">
                    {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 0 && refundrRecordLength >= 2">
                    {{ scope.row.money ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 1">
                    {{ scope.row.money != undefined ? (scope.row.money / 100).toFixed(2) : "" }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 2 && refundrRecordLength < 2">
                    {{
                      currentChargeVO.money != undefined
                        ? (originalChargeVO.money - currentChargeVO.money) / (100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 0 && scope.$index == 2 && refundrRecordLength >= 2">
                    {{
                      currentChargeVO.money != undefined
                        ? (actualPayMoneyVal - currentChargeVO.money) / (100).toFixed(2)
                        : ""
                    }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 0">
                    {{ (scope.row.money / 100).toFixed(2) }}
                  </p>
                  <p v-if="form.region == 1 && scope.$index == 1">0.00</p>
                  <p v-if="form.region == 1 && scope.$index == 2">
                    {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                  </p>
                  <p v-if="form.region == 2 && scope.$index == 0">
                    {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                  </p>
                  <!--<input style="width: 80%" type="text" v-model="scope.row.money" v-if="form.region === 2 && scope.$index === 2">-->
                  <input
                    style="width: 80%"
                    type="text"
                    v-model="actualMoney"
                    v-if="form.region === 2 && scope.$index === 2"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="form.date4" style="width: 400px" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" @click="submitValidation()">提交 </el-button>
          <el-button
            @click="
              dialogVisible = false;
              radioFlag = false;
            "
            >取 消</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcel, setIndex } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";

export default {
  name: "applyFor",
  data() {
    let that = this;
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      areaIds: "",
      defalutDate: [],
      refundTableData: [],
      // refundTableData: [{
      //   date: '2016-05-02',
      //   name: '1.00',
      //   address: '300.00',
      // },
      //   {
      //     date: '2016-05-03',
      //     name: '9.00',
      //     address: '400.00',
      //   },
      //   {
      //     date: '2016-05-02',
      //     name: '8.00',
      //     address: '600.00',
      //   }
      //   ],  // todo
      allAreaList: "",
      endPickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date(that.tableData2[0].exitTime * 1);
        },
      },
      form: {
        name: "",
        region: 0,
        date1: "原路退回",
        date2: "",
        date3: "",
        date4: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        value1: "",
      },
      orderMoney: "",
      causeData: "",
      i: 1,
      radio: "",
      radioFlag: false,
      tableData2: [],
      flag: false,
      timeFlag: true,
      selectFlag: true,
      plateNumber: "",
      windowplateNumber: "",
      rowData: 0,
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      causeDownData: [],
      parkRecordId: "",
      paymentId: "",
      payType: 0,
      carId: "",
      berthId: "",
      parkId: "",
      entryTime: "",
      exitTime: "",
      balance: "",
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "actualPay",
          label: this.$t("list.Actual_received_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableOrder: [
        {
          label: "订单编号",
          prop: "payOrderId",
          width: "200",
        },
        {
          label: this.$t("list.payment_time"),
          prop: "payTime",
          width: "200",
        },
        {
          label: "支付方式",
          prop: "payTypeDesc",
          width: "130",
        },
        // todo
        {
          label: "应付金额",
          prop: "shouldPay",
          width: "130",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          prop: "agioPay",
          width: "130",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        // {
        //   label: this.$t("list.order_amount"),
        //   prop: "money",
        //   width: "90",
        //   formatter: (row, column) => {
        //     if (row.money) {
        //       return Number(row.money / 100).toFixed(2);
        //     } else {
        //       return "0.00";
        //     }
        //   }
        // },
        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付流水号",
          prop: "tradeNo",
          width: "",
        },
      ],
      tableData: [],
      parkDetail: [],
      refundrRecord: [],
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        refundRecordId: "",
      },
      currentChargeVO: "",
      originalChargeVO: "",
      actualPaymoney: "", // 实际退款金额
      refundPay1: "",
      actualMoney: "",
      actualdiscountMoney: "",
      actualcouponMoney: "",
      actualparkCardMoney: "",
      parkCardRefundPay: "",
      parkDiscountRefundPay: "",
      agioRefundPay: "",
      actualPayMoneyVal: "",
      refundrRecordLength: 0,
    };
  },
  watch: {
    formInline(newVal) {
      if (!newVal.plateNumber) {
        this.formInline.carId = "";
      }
    },
    deep: true,
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    changeReason() {
      if (!this.radioFlag) {
        this.$alert("请选择订单", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.form.region == 2) {
        this.actualparkCardMoney = Number(this.refundTableData[0].parkCardMoney / 100).toFixed(2);
        this.actualcouponMoney = Number(this.refundTableData[0].couponMoney / 100).toFixed(2);
        this.actualdiscountMoney = Number(this.refundTableData[0].discountMoney / 100).toFixed(2);
        this.actualMoney = Number(this.refundTableData[0].money / 100).toFixed(2);
      }
    },
    // 计算实际收费
    calculate(value) {
      let entryTime = this.tableData2[0].entryTime;
      let exitTime = this.tableData2[0].exitTime;
      let newEntryTime = value.getTime();
      this.timeFlag = true;
      if (newEntryTime < entryTime) {
        this.$alert("实际出场时间必须大于等于入场时间或小于等于出场时间！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          center: true,
        });
        this.timeFlag = false;
      } else if (newEntryTime > exitTime) {
        this.$alert("实际出场时间必须大于等于入场时间或小于等于出场时间！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          center: true,
        });
        this.timeFlag = false;
      } else {
        if (!this.radioFlag) {
          this.$alert("请选择订单", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.$axios
          // .get("/acb/2.0/feeScene/charge/calc", {
          .get("/acb/2.0/refundRecord/charging", {
            data: {
              carId: this.carId,
              berthId: this.berthId,
              parkId: this.parkId,
              entryTime: dateFormat(
                new Date(this.tableData2[0].entryTime * 1),
                "yyyy-MM-dd HH:mm:ss"
              ),
              exitTime: dateFormat(value, "yyyy-MM-dd HH:mm:ss"),
              parkRecordId: this.parkRecordId,
              payOrderId: this.payOrderId,
            },
          })
          .then((res) => {
            this.currentChargeVO = res.value;
            this.form.date2 = (res.value.money / 100).toFixed(2);
            // this.refundTableData = res.value;
            this.refundTableData.splice(1, 1, res.value);
            // this.refundTableData.push(res.value);
          });
      }
    },
    fn(scope) {
      this.$refs.form && this.$refs.form.resetFields();
      this.form.region = 0;
      this.form.date2 = "";
      this.form.value1 = "";
      this.form.date3 = "";
      this.form.date4 = "";
      this.radio = "";
      this.orderMoney = "";
      this.payOrderId = "";
      this.dialogVisible = true;
      this.tableData2 = [];
      this.tableData2.push(scope.row);
      console.log(this.tableData2);
      this.actualPayMoneyVal = this.tableData2[0].actualPay;
      // paymentId
      this.paymentId = scope.row.paymentId;
      // 支付类型
      this.payType = scope.row.payType;
      this.rowData = scope.row.parkRecordId;
      this.windowplateNumber = scope.row.plateNumber;
      this.parkRecordId = scope.row.parkRecordId;
      this.carId = scope.row.carId;
      this.berthId = scope.row.berthId;
      this.parkId = scope.row.parkId;
      this.entryTime = scope.row.strEntryTime;
      this.exitTime = scope.row.strExitTime;
      this.getData();
    },
    getCurrentRow(a, b) {
      this.form.value1 = "";
      this.currentChargeVO = "";
      this.radioFlag = true;
      this.orderMoney = (a.money / 100).toFixed(2);
      this.payOrderId = a.payOrderId;
      // 支付类型
      this.payTyperes = a.payType;
      this.chargeDetail(a.payOrderId);
    },
    // 支付记录订单明细接口
    chargeDetail(payOrderId) {
      this.refundTableData = [];
      this.$axios
        .get("/acb/2.0/refundRecord/payOrder/chargeVO", {
          data: {
            payOrderId: payOrderId,
          },
        })
        .then((res) => {
          this.originalChargeVO = res.value;
          // JSON.stringify(this.originalChargeVO)
          // this.data = {
          //   originalChargeVO: this.originalChargeVO
          // }
          this.refundTableData.push(res.value);
          this.refundTableData.push({});
          this.refundTableData.push(res.value);
          if (this.form.region == 2) {
            this.actualparkCardMoney = Number(this.refundTableData[0].parkCardMoney / 100).toFixed(
              2
            );
            this.actualcouponMoney = Number(this.refundTableData[0].couponMoney / 100).toFixed(2);
            this.actualdiscountMoney = Number(this.refundTableData[0].discountMoney / 100).toFixed(
              2
            );
            this.actualMoney = Number(this.refundTableData[0].money / 100).toFixed(2);
          }
          console.log(this.refundTableData, this.refundrRecord.length);
          this.refundrRecordLength = this.refundrRecord.length;
          // this.refundrRecord = res.value.list;
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    getData() {
      this.refundTableData = [];
      this.currentChargeVO = "";
      this.$axios
        .get("/acb/2.0/parkRecord/payOrder/" + this.rowData, {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.refundrRecord = res.value.list;
          console.log("refundrRecord--->", this.refundrRecord);
        });
    },
    // getData() {
    //   // console.log('支付进来')
    //   this.$axios
    //     .get("/acb/2.0/parkRecord/payOrder/" + this.rowData, {
    //       data: {
    //         page: 0,
    //         pageSize: 0
    //       }
    //     })
    //     .then(res => {
    //       this.refundrRecord = res.value.list;
    //     });
    // },
    causeDown() {
      this.$axios.get("/acb/2.0/refundRecord/spinner").then((res) => {
        this.causeDownData = res.value.refundReasonType;
      });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(data) {
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    clildrenHandleCurrentChange(val) {
      this.pageNum = val;
      this.getOrder();
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            areaIds: areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
        //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
        // }
      });
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 获取当前用户的区域列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/getAllArea").then((res) => {
        this.allAreaList = res.value[0].areaId;
        this.areaList = res.value[0].childrenAreas;
        this.formInline.areaId = res.value[0].areaId;
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId: areaId,
          parkId: this.formInline.parkId,
          operationId: this.formInline.operationId,
          parkType: this.formInline.parkType,
          entryOrExit: this.formInline.entryOrExit,
          startTime: this.formInline.startTime ? this.formInline.startTime : this.date1,
          endTime: this.formInline.endTime ? this.formInline.endTime : this.date2,
          plateNumber: this.formInline.plateNumber,
          carId: this.formInline.carId,
          exclude: 1,
          // TOGAC-11295 dataSource=0 查询的是全部的，包含路内路外
          dataSource: 0,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      //    if (this.formInline.areaId != '' && this.formInline.parkId == '') {
      //      this.$refs.parkInput.setValue('');
      //      this.$alert('选择区域后，请选择指定车场再进行搜索', this.$t('pop_up.Tips'), {
      //        confirmButtonText: this.$t('pop_up.Determine')
      //      });
      //      return;
      //    }
      //    if (this.formInline.operationId != '' && this.formInline.parkId == '') {
      //      this.$alert('选择商户后，请选择指定车场再进行搜索', this.$t('pop_up.Tips'), {
      //        confirmButtonText: this.$t('pop_up.Determine')
      //      });
      //      return;
      //    }
      // todo
      // if (this.formInline.plateNumber == "" || this.formInline.carId == "") {
      //   this.formInline.plateNumber = "";
      //   this.$alert("必须输入3位车牌号后选择对应车牌号才能搜索", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   this.$refs.parkInput.setShowVal("");
      //   return;
      // }
      // this.searParkRecordListFun();
      let flag = this.showLog();
      //    this.$refs['searchWrapper'].validate((v) => {
      //      if (v) {
      if (flag) {
        // this.loading = true;
        this.flag = false;
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.searParkRecordListFun();
      }
      //      }
      //    })
    },
    // 订单记录
    getOrder() {
      let page = this.pageNum || 1;
      let opt = {
        url: "/acb/2.0/parkRecord/payOrder/" + this.rowData,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value.list;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 导出
    exportFile() {
      exportExcel("/gplus/chargePayment/exportUserPayments", this.formInline);
    },
    // 提交前验证
    submitValidation() {
      // this.form.region==0 出场延时, 1 车牌错误, 2 其它
      // actualRefundPay 实际退款金额
      // refundPay 应退款金额
      var a = this.originalChargeVO.money ? this.originalChargeVO.money : "0";
      var b = this.originalChargeVO.discountMoney ? this.originalChargeVO.discountMoney : "0";
      var c = this.originalChargeVO.couponMoney ? this.originalChargeVO.couponMoney : "0";
      var d = this.originalChargeVO.parkCardMoney ? this.originalChargeVO.parkCardMoney : "0";
      var a1 = this.currentChargeVO.money ? this.currentChargeVO.money : "0";
      var b1 = this.currentChargeVO.discountMoney ? this.currentChargeVO.discountMoney : "0";
      var c1 = this.currentChargeVO.couponMoney ? this.currentChargeVO.couponMoney : "0";
      var d1 = this.currentChargeVO.parkCardMoney ? this.currentChargeVO.parkCardMoney : "0";
      if (this.form.region == 0) {
        // this.actualPaymoney = this.originalChargeVO.money ? Math.abs((this.originalChargeVO.money - this.currentChargeVO.money) / 100 .toFixed(2)) : ''
        if (this.refundrRecordLength < 2) {
          this.actualPaymoney = a - a1;
        } else {
          this.actualPaymoney = this.actualPayMoneyVal - this.currentChargeVO.money;
        }
        this.refundPay1 = Number(a - a1 + (b - b1) + (c - c1) + (d - d1));
        this.agioRefundPay = c - c1;
        this.parkDiscountRefundPay = b - b1;
        this.parkCardRefundPay = d - d1;
      } else if (this.form.region == 1) {
        this.actualPaymoney = Number(a);
        this.agioRefundPay = Number(c);
        this.parkDiscountRefundPay = Number(b);
        this.parkCardRefundPay = Number(d);
        this.refundPay1 = Number(a) + Number(b) + Number(c) + Number(d);
      } else if (this.form.region == 2) {
        this.actualPaymoney = Math.round(this.actualMoney * 100); // 实付金额
        this.agioRefundPay = Math.round(this.actualcouponMoney * 100); // 优惠券
        this.parkDiscountRefundPay = Math.round(this.actualdiscountMoney * 100); // 车场折扣
        this.parkCardRefundPay = Math.round(this.actualparkCardMoney * 100); // 停车卡抵扣
        this.refundPay1 =
          this.actualPaymoney +
          this.agioRefundPay +
          this.parkDiscountRefundPay +
          this.parkCardRefundPay;
        //        // console.log(((this.actualMoney * 100) + (this.actualdiscountMoney * 100)  + (this.actualcouponMoney * 100) + (this.actualparkCardMoney* 100)), 'ppppp')
        //        // console.log((this.actualMoney + this.actualdiscountMoney  + this.actualcouponMoney + this.actualparkCardMoney) * 100, 'ppppp')
      }
      if (!this.radioFlag) {
        this.$alert("请选择订单", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.form.region === 2) {
        if (b < Math.round(this.actualdiscountMoney * 100)) {
          this.$alert("退款车场折扣金额不能大于原订单优惠金额", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (c < Math.round(this.actualcouponMoney * 100)) {
          this.$alert("退款优惠券抵扣金额不能大于原订单优惠金额", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (d < Math.round(this.actualparkCardMoney * 100)) {
          this.$alert("退款停车卡金额不能大于原订单优惠金额", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (a < Math.round(this.actualMoney * 100)) {
          this.$alert("退款实付金额不能大于原订单金额", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        var reg = /^[0-9]+(.[0-9]{1,2})?$/;
        if (
          !reg.test(this.actualparkCardMoney) ||
          !reg.test(this.actualcouponMoney) ||
          !reg.test(this.actualMoney) ||
          !reg.test(this.actualdiscountMoney)
        ) {
          this.$alert("退款金额必须大于0且最大支持2位小数");
          return false;
        }
      }
      if (this.form.region === 0) {
        if (this.form.value1 === "") {
          this.$alert("实际出场时间不能为空", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        } else if (!this.timeFlag) {
          this.$alert("实际出场时间必须大于等于入场时间或小于等于出场时间！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      console.log("submitValidation-->", this.actualPaymoney);
      if (this.actualPaymoney / 100 <= 0) {
        this.$alert("实付金额必须大于0", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      // if (
      //   parseFloat(this.form.date3) > parseFloat(this.orderMoney) ||
      //   this.form.date3 === "" ||
      //   parseFloat(this.form.date3) <= 0
      // ) {
      //   this.$alert("退款金额不能大于订单金额，不能为空或小于等于0", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   return;
      // }

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    // 提交
    submit() {
      this.radioFlag = false;
      this.dialogVisible = false;
      this.$axios
        .post("/acb/2.0/refundRecord/create", {
          data: {
            // 实际出场时间  可否
            actualExitTime: this.form.value1
              ? dateFormat(this.form.value1, "yyyy-MM-dd HH:mm:ss")
              : "",
            // 退款金额  计算的金额
            // refundPay: parseInt(this.orderMoney * 100),
            refundPay: this.actualPaymoney,
            // 实际退款金额actualRefundPay  输入的金额
            // actualRefundPay: parseInt(this.form.date3 * 100),
            actualRefundPay: this.actualPaymoney,
            // 退款原因
            refundReason: this.form.region,
            // 备注描述
            remarks: this.form.date4,
            // 停车记录id parkRecordId
            parkRecordId: this.parkRecordId,
            // 支付记录id paymentId
            payOrderId: this.payOrderId,
            // 车牌号 plateNumber
            plateNumber: this.windowplateNumber,
            // 支付类型 payType
            payType: this.payTyperes,
            // 原订单计费明细
            originalChargeVO: JSON.stringify(this.originalChargeVO),
            // 当前退费计费明细
            currentChargeVO: JSON.stringify(this.currentChargeVO),
            // 停车卡抵扣金额
            parkCardRefundPay: this.parkCardRefundPay,
            // 车场折扣金额
            parkDiscountRefundPay: this.parkDiscountRefundPay,
            // 优惠券金额
            agioRefundPay: this.agioRefundPay,
          },
        })
        .then((res) => {
          this.$alert("退款申请已提交审核", "提示");
          setTimeout((_) => {
            this.searParkRecordList();
          }, 300);
        });
    },
  },
  components: {
    myComponent,
    timeRangePick,
  },
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // 退款原因跟下拉
    this.causeDown();
    // this.parkData()
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    this.showLog();
    this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px

.conborder
  border-top 1px solid #C0CCDA

  overflow hidden
  padding 10px

.content
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px

  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px

    .export
      font-size 12px
      border 1px solid #0099FA
      border-radius 4px
      color #0099FA
      padding 8px 13px
      cursor pointer

      .iconfont
        margin-right 14px

.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0

  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100

    .el-icon-close
      float right
      margin-top 14px

  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit

.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999

.practicalCome
  display flex

.el-radio__label
  display inline-block
  display none

.is-leaf
  text-align center

.titleh1
  margin 10px 0
  font-size 15px

.el-form
  margin 10px 0
</style>
<style type="text/css">
.searchWrapperContent .el-radio__label {
  display: inline-block;
  text-indent: -9999px;
}

.apply_for_date_picker .el-picker-panel__footer .el-button--text {
  display: none !important;
}
</style>
